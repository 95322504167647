import React from 'react';
import styles from './recognition.module.css';
import Accomplishments from './accomplishments';
import Awards from './awards';

function Recognition() {
  return (
    <div className={styles.container}>
      <Accomplishments />
      <Awards />
    </div>
  );
}

export default Recognition;
