import React, { memo } from 'react';
import Img from 'gatsby-image';
import GoogFirms from '../../images/good-firms.inline.svg';
import OutsourcingCompanies from '../../images/outsourcing-companies.inline.svg';
import Upwork from '../../images/upwork-logo.inline.svg';
import { graphql, useStaticQuery } from 'gatsby';

import clsx from 'clsx';
import styles from './recognition.module.css';

interface IAwards {
  isTitled?: boolean;
  titleStyles?: string;
  listStyles?: string;
}

const Awards = ({ isTitled, titleStyles, listStyles }: IAwards) => {
  const data = useStaticQuery(graphql`
    query {
      youTeam: file(relativePath: { eq: "you-team-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 125, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      topMobileDev2: file(relativePath: { eq: "top-mobile-app-dev-companies.png" }) {
        childImageSharp {
          fluid(maxWidth: 636, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      clutch: file(relativePath: { eq: "logo-clutch.png" }) {
        childImageSharp {
          fluid(maxWidth: 125, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <>
      {isTitled ? <h2 className={clsx(styles.title, titleStyles)}>Awards</h2> : false}
      <ul className={clsx(styles.list, listStyles)}>
        <li className={styles.item} key="clutch">
          <a
            href="https://clutch.co/profile/brocoders"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            <Img
              fluid={data.clutch.childImageSharp.fluid}
              className={clsx(styles.logo)}
              alt="Clutch"
            />
          </a>
        </li>
        <li className={styles.item} key="goodfirms">
          <a
            target="_blank"
            href="https://www.goodfirms.co/company/brocoders"
            rel="noopener noreferrer"
            className={styles.link}
            title="good firms"
          >
            <GoogFirms className={clsx(styles.logo, styles.logo2)} />
          </a>
        </li>
        <li className={styles.item} key="youteam">
          <a
            href="https://youteam.io"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            <Img
              fluid={data.youTeam.childImageSharp.fluid}
              className={clsx(styles.logo, styles.logo3)}
              alt="You team"
            />
          </a>
        </li>
        <li className={styles.item} key="upwork">
          <a
            href="https://www.upwork.com/o/companies/~012e8207026744bf72/"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
            title="Upwork"
          >
            <Upwork className={clsx(styles.logo, styles.logo4)} />
          </a>
        </li>
        <li className={styles.item} key="designrush">
          <a
            href="https://www.designrush.com/agency/profile/brocoders"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
            title="outsourcing companies"
          >
            <OutsourcingCompanies className={clsx(styles.logo, styles.logo5)} />
          </a>
        </li>
      </ul>
    </>
  );
};
export default memo(Awards);
