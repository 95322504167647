import React from 'react';
import styles from './recognition.module.css';

const Accomplishments = () => {
  return (
    <div className={styles.benefits}>
      <dl>
        <dt className={styles.point}>85</dt>
        <dd className={styles.description}>projects done</dd>
      </dl>
      <dl>
        <dt className={styles.point}>87</dt>
        <dd className={styles.description}>top specialists</dd>
      </dl>
      <dl>
        <dt className={styles.point}>8</dt>
        <dd className={styles.description}>years of experience</dd>
      </dl>
      <dl>
        <dt className={styles.point}>30</dt>
        <dd className={styles.description}>5.0 reviews on Clutch</dd>
      </dl>
    </div>
  );
};

export default Accomplishments;
